import React, { useState } from 'react';
import {
  Show,
  TopToolbar,
  Button,
  ListButton,
  EditButton,
  TabbedShowLayout,
  Tab,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  FunctionField,
  ArrayField,
  BooleanField,
  SelectField,
  CreateButton,
  usePermissions,
} from 'react-admin';

import IconFileCopy from '@material-ui/icons/FileCopy';

import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';
import VoucherList from '../Voucher';
import CashbackList from '../Cashback';
import config from '../../config';
import DuplicateFormDialog from './DuplicateFormDialog';
import { ExternalLinkIcon } from '../Shared/Components/ExternalLinkIcon';

const renderHDRValue = (record) => `${record.grossAmount} ${record.type === 'FLAT' ? record.currency : '%'} (${record.amount} ${record.type === 'FLAT' ? record.currency : '%'} NET)`;
const renderShopLink = (record) => record.shop && (
  <a href={`#/shops/${record.shop.id}/show`} target="_blank" rel="noopener noreferrer">
    {record.shop.domain}
    {' '}
-
    {' '}
    {record.shop.country.iso2a}
  </a>
);

/**
 * ProgramShow
 */
function ProgramShow(props) {
  const { id } = props;
  const { permissions } = usePermissions();
  const [isDuplicateDialogOpened, setIsDuplicateDialogOpened] = useState(false);

  return (
    <>
      <Show
        actions={<ProgramActions openDuplicateDialog={() => setIsDuplicateDialogOpened(true)} />}
        title={<LayoutTitle source="name" />}
        {...props}
      >
        <TabbedShowLayout>
          <Tab label="Résumé">
            <TextField source="id" />
            <TextField source="name" label="Nom" />

            <TextField source="networkInternalId" />
            <TextField source="network.name" label="Network" />

            <FunctionField label="shop" render={renderShopLink} />

            <TextField source="url" label="Url" />
            <TextField source="status" label="Status" />

            <NumberField source="priority" label="Priorité" />
            <TextField source="type" />
            <TextField source="userSegment" emptyText="N/A" />

            <BooleanField label="Désactivation manuelle" source="manualDeactivation" />
            <BooleanField label="Désactivation extension desktop" source="extensionDesktopDeactivation" />
            <BooleanField label="Désactivation extension iOS" source="extensionIosDeactivation" />
            <TextField label="Désactivation Commentaire" source="manualDeactivationComment" />

            <DateField source="updated" showTime locales="fr-FR" label="Dernière mise à jour" />
          </Tab>

          <Tab label="HDR">
            <ArrayField source="hdr" addLabel={false}>
              <Datagrid>
                <FunctionField label="Valeur" render={renderHDRValue} />
                <SelectField source="currency" label="Devise" choices={config.currencies} />
                <DateField source="start" label="Du" showTime locales="fr-FR" />
                <DateField source="end" label="Au" showTime locales="fr-FR" />
                <NumberField source="fixedCosts" label="Frais fixes" />
                <BooleanField source="isPartial" label="HDR partielle" />
                <TextField style={{ maxWidth: '400px', 'white-space': 'pre-wrap' }} component="pre" source="comment" label="Commentaire" />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label="Coupons" path="vouchers">
            <RelatedCouponsList {...props} />
          </Tab>

          <Tab label="Taux de reversement" path="share-policy">
            <TopToolbar>
              {permissions && permissions.programs.write && (
                <CreateButton
                  to={{
                    pathname: '/share-policy/create',
                    search: `?programId=${id}`,
                  }}
                />
              )}
            </TopToolbar>
            <ArrayField source="sharePolicy.edges.node" addLabel={false}>
              <Datagrid>
                <TextField source="reason" label="Commentaire" />
                <FunctionField label="Valeur" sortable={false} render={(record) => `${record.value}${record.type === 'PERCENTAGE' ? '%' : 'EUR'}`} />
                <FunctionField label="Valeur maximale" sortable={false} render={(record) => `${record.maxValue}${record.maxType === 'PERCENTAGE' ? '%' : 'EUR'}`} />
                <DateField source="created" locales="fr-FR" label="Créé le" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          {permissions && permissions.programs.cashbacks.read && (
            <Tab label="Offres" path="cashbacks">
              <RelatedCashbacksList {...props} />
            </Tab>
          )}

          <Tab label="Historique" path="histories">
            <ArrayField source="histories" addLabel={false}>
              <Datagrid>
                <DateField source="historyCreation" showTime sortable={false} label="Action Date" locales="fr-FR" />
                <TextField source="program.type" label="Type" sortable={false} />
                <TextField source="program.url" label="URL" sortable={false} />
                <TextField source="program.status" label="Etat" sortable={false} />
                <BooleanField source="program.manualDeactivation" label="Désactivation Manuelle" sortable={false} />
                <BooleanField source="program.extensionDesktopDeactivation" label="Désactivation Extension desktop" sortable={false} />
                <BooleanField source="program.extensionIosDeactivation" label="Désactivation Extension iOS" sortable={false} />
                <TextField source="program.manualDeactivationComment" label="Désactivation Commentaire" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
      <DuplicateFormDialog
        programId={id}
        isDialogOpened={isDuplicateDialogOpened}
        onDialogClosed={() => setIsDuplicateDialogOpened(false)}
      />
    </>

  );
}

ProgramShow.propTypes = {
  id: PropTypes.string,
};

function RelatedCouponsList(props) {
  const { record } = props;

  record.shopId = record.shop.id;

  const updatedProps = {
    ...props,
    record: {
      ...record,
      shopId: record.shop.id,
    },
    source: 'shopId',
  };

  return <VoucherList {...updatedProps} shopId={record.shopId} />;
}

RelatedCouponsList.propTypes = {
  record: PropTypes.object,
};

function RelatedCashbacksList(props) {
  const { record } = props;

  return <CashbackList {...props} reference="programs/cashbacks" programId={record.id} />;
}

RelatedCashbacksList.propTypes = {
  record: PropTypes.object,
};

const EditHdrLink = ({ programId }) => (
  <div style={{ padding: '2px 5px 4px', fontSize: '13px', fontWeight: '500' }}>
    <a
      href={`${config.services.boCashback.url}/program/${programId}`}
      target="_blank"
      className="external-link MuiButton-textPrimary"
      rel="noreferrer"
    >
      <span>EDITER HDR</span>
      <ExternalLinkIcon color="#3f51b5" />
    </a>
  </div>
);

EditHdrLink.propTypes = {
  programId: PropTypes.string,
};

function ProgramActions({ basePath, data, openDuplicateDialog }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
    <EditHdrLink programId={data?.id} />
    <Button label="Dupliquer" onClick={openDuplicateDialog}>
      <IconFileCopy />
    </Button>
</TopToolbar>
  );
}

ProgramActions.propTypes = ActionsTypes.Show;

export default ProgramShow;
