import { COUNTRY_CODES } from './countries';

export const BANNER_TYPES = {
  [COUNTRY_CODES.FR]: [
    // fix pages
    'HOMEPAGE',
    'CAGNOTTE',
    // event pages
    'coups-de-coeur',
    'boost-cashback',
    'soldes-hiver',
    'saint-valentin',
    'festival-voyages-locations',
    'french-days',
    'fete-des-meres',
    'fete-des-peres',
    'soldes-dete',
    'competitions-sportives',
    'rentree-des-classes',
    'singles-day',
    'blackfriday',
    'noel',
    'wanteeed-days',
    // category pages
    'entreprise',
    'alimentation-vins',
    'autos-motos',
    'banques-assurances',
    'beaute-bienetre',
    'cadeaux',
    'enfant',
    'high-tech-electromenager',
    'loisirs-culture',
    'maison-jardin',
    'marketplaces',
    'mode-accessoires',
    'restauration',
    'sport',
    'voyages-et-locations',
  ],
  [COUNTRY_CODES.GB]: [
    // fix pages
    'HOMEPAGE',
    'CAGNOTTE',
    // event pages
    'favorites',
  ],
};

export const formatBannerType = (bannerType) => bannerType.toUpperCase().replaceAll('-', ' ');
