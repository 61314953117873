import React from 'react';
import {
  usePermissions,
  Edit,
  TopToolbar,
  ShowButton,
  ListButton,
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  DeleteButton,
  Toolbar,
} from 'react-admin';
import { omit } from 'lodash';

import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';

const optionRenderer = (a) => a && `${a.domain.replace('*.', '')} (${a.url}) - ${a.country.iso2a}`;
const inputFunction = (input, suggestion, getOptionText) => {
  if (input.trim().length > 2) {
    return input.toLowerCase().trim() === getOptionText(suggestion).toLowerCase().trim();
  }
  return false;
};

function SelectProgramTypeInput(props) {
  if (!props.record || !props.record.type) return null;

  const programType = props.record.type.toUpperCase();
  let options = [
    { id: 'CASHBACK', name: 'CASHBACK' },
    { id: 'EXCLUSIVE', name: 'EXCLUSIVE' },
    { id: 'VOUCHER', name: 'VOUCHER' },
    { id: 'SOFTCLICK', name: 'SOFTCLICK' },
    { id: 'FALLBACK', name: 'FALLBACK' },
  ];

  if (['CASHBACK', 'EXCLUSIVE'].includes(programType)) {
    options = options.map((option) => ({
      ...option,
      disabled: ['VOUCHER', 'SOFTCLICK', 'FALLBACK'].includes(option.id),
    }));
  }

  if (['VOUCHER', 'SOFTCLICK', 'FALLBACK'].includes(programType)) {
    options = options.map((option) => ({
      ...option,
      disabled: ['CASHBACK', 'EXCLUSIVE'].includes(option.id),
    }));
  }

  return (
    <SelectInput
      label="Type de programme"
      source="type"
      choices={options}
    />
  );
}

const SelectUserSegmentInput = () => {
  const userSegments = [
    { id: null, name: 'Aucun' },
    { id: '01_conquete', name: '01_conquete' },
    { id: '02_fidelisation', name: '02_fidelisation' },
    { id: '03_lien_tracke', name: '03_lien_tracke' },
    { id: '04_code_promo', name: '04_code_promo' },
    { id: '05_visite', name: '05_visite' },
  ];

  return <SelectInput label="User Segment" source="userSegment" choices={userSegments} />;
};

const validateUrl = (url, formModel) => {
  const noError = undefined;
  const invalidUrlError = 'Should be a valid url starting with http:// or https://';

  // empty url not allowed
  if (!url) return invalidUrlError;

  // Since new URL() allow 'http:site.com'
  // we use the httpProtocolRegex to make sure the double slash are present
  const httpProtocolRegex = new RegExp(/^((http|https):\/\/)/);
  if (!httpProtocolRegex.test(url)) return invalidUrlError;

  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (error) {
    return invalidUrlError;
  }

  // No required hashid for fallback program since the commission always go to Wanteeed
  if (formModel.type === 'FALLBACK') return noError;

  const hasValidHashId = parsedUrl.href.match(/(\{HASHID\})|(%7BHASHID%7D)/g);
  if (!hasValidHashId) return 'Missing {HASHID} query param';

  return noError;
};

SelectProgramTypeInput.propTypes = {
  record: PropTypes.object,
};

/**
 * ProgramEdit
 */
function ProgramEdit(props) {
  const ownProps = { ...omit(props, ['hasList', 'hasShow', 'hasEdit', 'hasCreate']) };
  const { permissions } = usePermissions();

  return (
    <Edit undoable={false} actions={<ProgramActions permissions={permissions ? permissions.programs : {}} />} title={<LayoutTitle source="name" />} {...ownProps}>
      <TabbedForm toolbar={<CustomToolbar permissions={permissions ? permissions.shops : {}} />} redirect="show" {...ownProps}>
        <FormTab variant="standard" label="Résumé">
          <TextInput disabled source="id" />
          <TextInput source="name" label="Nom" />

          <TextInput disabled source="networkInternalId" />
          <TextInput disabled source="network.name" label="Network" />

          <ReferenceInput perPage={10} label="Shop" source="shop.id" reference="shops" fullWidth options={{ fullWidth: true }}>
            <AutocompleteInput optionText={optionRenderer} inputValueMatcher={inputFunction} />
          </ReferenceInput>

          <TextInput source="url" label="Url" fullWidth validate={validateUrl} />
          <TextInput disabled source="status" label="Status" />

          <NumberInput source="priority" step={1} label="Priorité" />

          <SelectProgramTypeInput />
          <SelectUserSegmentInput />

          <BooleanInput label="Désactiver le programme" source="manualDeactivation" />
          <BooleanInput label="Désactiver le programme pour l'extension sur desktop" source="extensionDesktopDeactivation" />
          <BooleanInput label="Désactiver le programme pour l'extension sur iOS" source="extensionIosDeactivation" />
          <TextInput multiline source="manualDeactivationComment" label="Désactivation Commentaire" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function ProgramActions({
  basePath,
  data,
  permissions,
}) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    { permissions.write && <ShowButton basePath={basePath} record={data} />}
</TopToolbar>
  );
}

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton {...props} />
    {/* eslint-disable-next-line react/prop-types */}
    { props.permissions.delete && <DeleteButton {...props} />}
</Toolbar>
  );
}

ProgramActions.propTypes = {
  ...ActionsTypes.Edit,
  permissions: PropTypes.object,
};

ProgramEdit.propTypes = {
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasCreate: PropTypes.bool,
};

export default ProgramEdit;
