import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AppBar as DefautAppBar, Layout as DefaultLayout } from 'react-admin';
import './AppBar.css';
import config from '../../config';
import { ExternalLinkIcon } from '../Shared/Components/ExternalLinkIcon';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

export const AppBar = (props) => {
  const classes = useStyles();

  return (
    <DefautAppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <a
        href={config.services.boCashback.url}
        target="_blank"
        className="external-link"
        rel="noreferrer"
      >
        <span>Nouveau BO</span>
        <ExternalLinkIcon />
      </a>
    </DefautAppBar>
  );
};

export const Layout = (props) => (
  <DefaultLayout {...props} appBar={AppBar} />
);
